import DataManagement from "store/models/DataManagement";
import AddressType from "store/models/AddressType";
import EmailTypes from "store/models/EmailType";
import PhoneTypes from "store/models/PhoneTypes";
import InsuranceCompany from "store/models/InsuranceCompany";
import Actions from "modules/actions";
import countryState from "countrycitystatejson";
import NpiActions from "store/actions/npi";
import InsuranceCompanyActions from "store/actions/insuranceCompany";
import TypesActions from "store/actions/types";
import BillingErrorCodeActions from "store/actions/billing-error-code";
import ClaimSubmissionMethodActions from "store/actions/claimSubmissionMethod";
import ClaimFilingIndicatorActions from "store/actions/claimFilingIndicator";
import EnrollmentStatusActions from "store/actions/enrollmentStatus";
import PayorFileAttachmentActions from "store/actions/payorAttachment";

const billingErrorCodeAction = new BillingErrorCodeActions();
const claimSubmissionMethodActions = new ClaimSubmissionMethodActions();
const enrollmentStatusActions = new EnrollmentStatusActions();
const claimFilingIndicatorActions = new ClaimFilingIndicatorActions();
const payorFileAttachmentActions = new PayorFileAttachmentActions();
const npiActions = new NpiActions();
const insuranceCompanyActions = new InsuranceCompanyActions();
const typesActions = new TypesActions();
export default class PayorActions extends Actions {
  async createPayor(variables) {
    return await insuranceCompanyActions.create(variables);
  }
  async createBillingErrorAccession(variables) {
    return await billingErrorCodeAction.createBillingErrorAccession(variables);
  }
  async updatePayor(variables) {
    return await insuranceCompanyActions.update(variables);
  }

  async activatePayor(variables) {
    return await insuranceCompanyActions.activate(variables);
  }

  async deactivatePayor(variables) {
    return await insuranceCompanyActions.deactivate(variables);
  }

  setDataManagement(param) {
    DataManagement.deleteAll();
    let dataManagement = new DataManagement();
    dataManagement.id = 1;

    if (param.payorId) dataManagement.payorUUID = param.payorId;

    if (param.isView) dataManagement.isView = param.isView;

    dataManagement.$save();
  }
  getSelectedPractice() {
    return DataManagement.find(1);
  }
  getSelectedPayor() {
    return DataManagement.find(1);
  }
  deleteDataManagement() {
    DataManagement.deleteAll();
  }

  async getAllAddressTypes() {
    return await typesActions.getAddressTypes();
  }
  getAddressTypes() {
    let types = AddressType.all();
    try {
      types.forEach((type, index) => {
        if (
          type.label.toLowerCase() === "other" ||
          type.label.toLowerCase() === "others"
        )
          types.push(types.splice(index, 1)[0]);
      });
      return types;
    } catch (e) {
      console.log(e);
    }
  }

  getAllEmailTypes() {
    let types = EmailTypes.all();
    try {
      types.forEach((type, index) => {
        if (
          type.label.toLowerCase() === "other" ||
          type.label.toLowerCase() === "others"
        )
          types.push(types.splice(index, 1)[0]);
      });
      return types;
    } catch (e) {
      console.log(e);
    }
  }
  async getEmailTypes() {
    return await typesActions.getEmailTypes();
  }

  async getPhoneTypes() {
    return await typesActions.getPhoneTypes();
  }
  getAllPhoneTypes() {
    let types = PhoneTypes.all();
    try {
      types.forEach((type, index) => {
        if (
          type.label.toLowerCase() === "other" ||
          type.label.toLowerCase() === "others"
        )
          types.push(types.splice(index, 1)[0]);
      });
      return types;
    } catch (e) {
      console.log(e);
    }
  }

  getAllCountryWithStates() {
    //will return all country and browse country.state to see the array of states for the country
    const countries = countryState.getCountries().map((country) => {
      return {
        id: country.shortName,
        name: country.name,
        states: this.formatState(
          countryState.getStatesByShort(country.shortName),
          country.shortName
        ),
      };
    });
    return countries.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
  }
  formatState(states, countryShortName) {
    let stateArr = [];
    for (var i = 0; i < states.length; i++) {
      // format id to camel case
      var stateId = states[i].toLowerCase();
      stateId = stateId.replace(/[()']/g, "");
      stateId = stateId.split(/[- ]/g);

      for (var k = 0; k < stateId.length; k++) {
        if (k !== 0) {
          stateId[k] = stateId[k].charAt(0).toUpperCase() + stateId[k].slice(1);
        }
      }

      stateId = stateId.join("");

      stateArr.push({
        id: stateId,
        countryId: countryShortName,
        name: states[i],
      });
    }

    return stateArr;
  }
  async fetchPayors(
    limit = null,
    offset = null,
    filter = null,
    limitData = null,
    order = null
  ) {
    let options = {
      relativeUrl: "/payor/get-all-payor",
      limit,
      offset,
      filter,
      order,
      queryName: "insuranceCompanies",
    };
    if (limitData) options["limitData"] = limitData;

    const response = await InsuranceCompany.api().query(
      options,
      "to get all test"
    );

    return response.entities ? response.response.data : [];
  }
  async getAllBillingError(
    limit = null,
    offset = null,
    filter = null,
    limitData = null,
    order = null
  ) {
    let options = {
      limit,
      offset,
      filter,
      order,
    };
    if (limitData) options["limitData"] = limitData;

    const response = await billingErrorCodeAction.all(options);

    return response;
  }
  async searchNpi(npiNumber) {
    return await npiActions.searchNpi(npiNumber);
  }

  async getClaimSubmissionMethods(variables = null) {
    return await claimSubmissionMethodActions.all(variables);
  }

  async getClaimFilingIndicators(variables = null) {
    return await claimFilingIndicatorActions.all(variables);
  }

  async getEnrollmentStatuses(variables = null) {
    return await enrollmentStatusActions.all(variables);
  }

  getSelectedEnrollment(id) {
    return InsuranceCompany.query().where("id", id).get();
  }

  async createPayorAttachment(variables = null) {
    return await payorFileAttachmentActions.create(variables);
  }

  async uploadFile(data, uuid, docType) {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("uuid", uuid);
    formData.append("docType", docType);
    return await InsuranceCompany.api().post("/payor/upload-file", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
