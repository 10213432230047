import VuetifyPdf from "vuetify-pdfjs/src/App.vue";
import axios from "axios";

export default {
  components: {
    VuetifyPdf,
  },
  props: {
    sectionDisabled: {
      type: Boolean,
      require: true,
    },
    sectionTitle: {
      type: String,
      require: true,
    },
    data: {
      type: Object,
      default: null,
    },
    optionEnrollment: {
      type: Array,
      require: true,
    },
    file: {
      type: File,
    },
    attachment: {
      type: Object,
      require: true,
    },
  },
  computed: {
    __File: {
      get() {
        return this.file;
      },
      set(value) {
        this.$emit("update:file", value);
      },
    },
    __Enrollment: {
      get() {
        if (this.data !== null)
          if (typeof this.data.requirement == "boolean") {
            this.data.requirement = this.data.requirement ? "yes" : "no";
          }
        return this.data;
      },
      set(value) {
        this.$emit("update:enrollment", value);
      },
    },
  },
  data() {
    return {
      hasAttachment: false,
      hasAttachmentCopy: false,
      attachmentEnrollment: null,
      enrollmentFileSrc: "",
      datePicker: {
        sDateShow: false,
        cDateShow: false,
      },
      date: {
        submitted: "",
        completed: "",
      },
      checkBox: "no",
      rules: {
        checkbox: {
          validation: false,
          required: [(val) => this.validateCheckbox(val)],
        },
        file: {
          fileSize: (file) =>
            this.validateFileSize(file, 1000) || "File must not more than 1 gb",
          required: (val) => {
            return (
              (typeof val === "object" && val !== null) ||
              "This field is required"
            );
          },
          specialCharac: (val) => {
            if (val)
              return (
                !/[#&+]/g.test(val.name) ||
                'File name must not have "#&+" characters.'
              );
            return true;
          },
        },
        required: (val) => {
          return (val !== null && val.length !== 0) || "This field is required";
        },
        validDate: (val) => this.isValidDate(val) || "Invalid date format.",
      },
    };
  },
  methods: {
    checkIfFilesCleared() {
      if (this.hasAttachmentCopy != this.hasAttachment) {
        return true;
      }
      return false;
    },
    clearFile() {
      this.hasAttachmentCopy = false;
    },
    preview() {
      window.open(this.enrollmentFileSrc);
    },
    async renderEnrollFile() {
      if (this.attachment.id) {
        this.hasAttachment = true;
        this.hasAttachmentCopy = true;
        this.attachmentFaxCover = JSON.parse(JSON.stringify(this.attachment));

        let file = {
          srcFilename: this.attachment.filename,
          destFilename: this.attachment.filename,
        };
        const result = await axios.get(
          `/payor/get-file?srcFilename=${file.srcFilename}&destFilename=${file.destFilename}&mimeType=application/pdf`,
          { responseType: "blob" }
        );
        if (result.status == 200) {
          const blob = new Blob([result.data], { type: "application/pdf" });
          this.enrollmentFileSrc = window.URL.createObjectURL(blob);
        }
      }
    },

    validateCheckbox(val) {
      if (!val) {
        this.rules.checkbox.validation = true;
        return false;
      }
      this.rules.checkbox.validation = false;
      return true;
    },
    parseDate(date) {
      if (!date || !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return "";
      return this.$options.filters.changeDateFormat(value, format);
    },
    async validateForm() {
      let validateForms = await this.$refs.form.validateAsync();
      return validateForms;
    },
  },
  async created() {
    setTimeout(async () => {
      await this.renderEnrollFile();
    }, 500);
  },
};
