import VuetifyPdf from "vuetify-pdfjs/src/App.vue";
import axios from "axios";

export default {
  name: "PriorAuthorization",
  components: {
    VuetifyPdf,
  },
  props: {
    sectionDisabled: {
      type: Boolean,
      require: true,
    },

    priorAuthorization: {
      type: Boolean,
      require: true,
    },
    priorDetails: {
      type: String,
      require: true,
    },
    priorNote: {
      type: String,
      require: true,
    },
    file: {
      type: File,
    },
    attachment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      hasAttachment: false,
      hasAttachmentCopy: false,
      attachmentPrior: null,
      priorAuthFileSrc: "",
      checkbox: "",
      rules: {
        checkbox: {
          validation: false,
          required: [(val) => this.validateCheckbox(val)],
        },
        file: {
          fileSize: (file) =>
            this.validateFileSize(file, 1000) || "File must not more than 1 gb",
          required: (val) => {
            return (
              (typeof val === "object" && val !== null) ||
              "This field is required"
            );
          },
          specialCharac:(val)=>{
            if(val)
              return !/[#&+]/g.test(val.name) || "File name must not have \"#&+\" characters.";
            return true
          }
        },
      },
    };
  },
  computed: {
    __File: {
      get() {
        return this.file;
      },
      set(value) {
        this.$emit("update:file", value);
      },
    },
    __PriorAuthorization: {
      get() {
        return this.priorAuthorization ? "yes" : "no";
      },
      set(value) {
        this.$emit("update:priorAuthorization", value == "yes" ? true : false);
      },
    },
    __PriorDetails: {
      get() {
        return this.priorDetails;
      },
      set(value) {
        this.$emit("update:priorDetails", value);
      },
    },
    __PriorNote: {
      get() {
        return this.priorNote;
      },
      set(value) {
        this.$emit("update:priorNote", value);
      },
    },
  },
  methods: {
    checkIfFilesCleared() {
      if (this.hasAttachmentCopy != this.hasAttachment) {
        return true;
      }
      return false;
    },
    preview() {
      window.open(this.priorAuthFileSrc);
    },
    clearFile() {
      this.hasAttachmentCopy = false;
    },
    async renderAuthFile() {
      if (this.attachment.id) {
        let file = {
          srcFilename: this.attachment.filename,
          destFilename: this.attachment.filename,
        };
        const result = await axios.get(
          `/payor/get-file?srcFilename=${file.srcFilename}&destFilename=${file.destFilename}&mimeType=application/pdf`,
          { responseType: "blob" }
        );
        if (result.status == 200) {
          const blob = new Blob([result.data], { type: "application/pdf" });
          this.priorAuthFileSrc = window.URL.createObjectURL(blob);
        }
      }
    },
    getQueryCollection() {
      let params = [];
      const authParam = this.__PriorAuthorization == "yes" ? true : false;
      params.push({
        updateAuthorizationRequirement: {
          authorizationRequirement: {
            type: "Boolean!",
            value: authParam,
          },
        },
      });
      params.push({
        updateAuthorizationRequirementDetails: {
          authorizationRequirementDetails: {
            type: "String!",
            value: this.__PriorDetails || "",
          },
        },
      });
      params.push({
        updateAuthorizationRequirementNote: {
          authorizationRequirementNote: {
            type: "String!",
            value: this.__PriorNote || "",
          },
        },
      });
      return params;
    },
    validateCheckbox(val) {
      if (!val) {
        this.rules.checkbox.validation = true;
        return false;
      }
      this.rules.checkbox.validation = false;
      return true;
    },
  },

  async mounted() {
    setTimeout(async () => {
      await this.renderAuthFile();
    }, 500);
  },
  async beforeMount() {
    this.attachmentPrior = JSON.parse(JSON.stringify(this.attachment));
  },
  created() {
    if (this.attachment.id) {
      this.hasAttachment = true;
      this.hasAttachmentCopy = true;
    }
  },
};
