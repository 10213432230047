import Fields from "./section";
import Actions from "modules/actions/payor-actions";
import { camelCase,upperFirst } from "lodash";

export default {
  name: "Enrollment",
  components: { Fields },
  props: {
    sectionDisabled: {
      type: Boolean,
      require: true,
    },
    payor: {
      type: Object,
      require: true,
    },
    ediAttachment: {
      type: Object,
      require: true,
    },
    eftAttachment: {
      type: Object,
      require: true,
    },
    eraAttachment: {
      type: Object,
      require: true,
    },
  },
  computed: {
    __Payor: {
      get() {
        return this.payor;
      },
      set(value) {
        this.$emit("update:payor", value);
      },
    },
  },
  watch: {
    enrollmentCopy: {
      deep: true,
      handler(newVal) {
        let copyNewVal = {};
        if(this.listen){
          Object.keys(newVal).forEach((index)=>{
            Object.keys(newVal[index]).forEach((childKey)=>{
              let group = index + "Enrollment" + upperFirst(childKey);
              copyNewVal[group] = newVal[index][childKey];
            })
          });
          Object.assign(this.__Payor, copyNewVal);
        }
      },
    },
  },
  data() {
    return {
      listen: false,
      ediFile: null,
      eftFile: null,
      eraFile: null,
      enrollmentCopy: [],
      statusOptions: [],
      uploadParam: null,
      actions: new Actions(),
    };
  },

  methods: {
    getSelectedPayor() {
      const data = JSON.parse(JSON.stringify(this.__Payor));
      const dataKeys = Object.keys(data);
      this.enrollmentCopy = dataKeys.reduce((result, key) => {
        if (key.includes("Enrollment")) {
          const group = key.slice(0, 3);
          const hasGroup = result[group] !== undefined;
          if (!hasGroup) result[group] = {};
          const newKey = camelCase(key.substring(3).replace("Enrollment", ""));
          if (!key.includes("Requirement")) {
            result[group][newKey] = data[key] != null ? data[key] : "";
          } else {
            result[group][newKey] = data[key] != null ? data[key] : false;
          }
        }
        return result;
      }, {});

      if(!this.sectionDisabled){
        Object.keys(this.enrollmentCopy).forEach((indexX)=>{
          this.statusOptions.forEach((indexY) => {
            if (
              !this.enrollmentCopy[indexX].status.id &&
              indexY.enrollmentStatus == "Submitted to payor"
            ) {
              this.enrollmentCopy[indexX].status.id = indexY.id;
            }
          });
        });
        setTimeout(()=>{
          this.listen =true
        }, 100)
      }
    },
    checkIfFilesCleared() {
      let enrollmentFileCleared = [
        this.$refs.ediForm.checkIfFilesCleared(),
        this.$refs.eraForm.checkIfFilesCleared(),
        this.$refs.eftForm.checkIfFilesCleared(),
      ];
      return enrollmentFileCleared.filter((bool) => bool).length >= 1;
    },
    getUploadParam() {
      this.uploadParam = {
        ediFile: this.ediFile,
        eftFile: this.eftFile,
        eraFile: this.eraFile,
      };
      return this.uploadParam;
    },
    getQueryCollection() {
      let params = [];

      if (this.enrollmentCopy.edi.status.id) {
        params.push({
          setEdiEnrollmentStatus: {
            ediEnrollmentStatusId: {
              type: "UUID",
              value: this.enrollmentCopy.edi.status.id,
            },
          },
        });
      }

      if (this.enrollmentCopy.eft.status.id) {
        params.push({
          setEftEnrollmentStatus: {
            eftEnrollmentStatusId: {
              type: "UUID",
              value: this.enrollmentCopy.eft.status.id,
            },
          },
        });
      }

      if (this.enrollmentCopy.era.status.id) {
        params.push({
          setEraEnrollmentStatus: {
            eraEnrollmentStatusId: {
              type: "UUID",
              value: this.enrollmentCopy.era.status.id,
            },
          },
        });
      }

      if (this.enrollmentCopy.edi.requirement) {
        const paramReq =
          this.enrollmentCopy.edi.requirement == "yes" ? true : false;
        params.push({
          updateEdiEnrollmentRequirement: {
            ediEnrollmentRequirement: {
              type: "Boolean!",
              value: paramReq,
            },
          },
        });
      }

      if (this.enrollmentCopy.eft.requirement) {
        const paramReq =
          this.enrollmentCopy.eft.requirement == "yes" ? true : false;
        params.push({
          updateEftEnrollmentRequirement: {
            eftEnrollmentRequirement: {
              type: "Boolean!",
              value: paramReq,
            },
          },
        });
      }

      if (this.enrollmentCopy.era.requirement) {
        const paramReq =
          this.enrollmentCopy.era.requirement == "yes" ? true : false;
        params.push({
          updateEraEnrollmentRequirement: {
            eraEnrollmentRequirement: {
              type: "Boolean!",
              value: paramReq,
            },
          },
        });
      }

      if (this.enrollmentCopy.edi.dateSubmitted) {
        params.push({
          updateEdiEnrollmentDateSubmitted: {
            ediEnrollmentDateSubmitted: {
              type: "String!",
              value: this.enrollmentCopy.edi.dateSubmitted,
            },
          },
        });
      }

      if (this.enrollmentCopy.edi.dateCompleted) {
        params.push({
          updateEdiEnrollmentDateCompleted: {
            ediEnrollmentDateCompleted: {
              type: "String!",
              value: this.enrollmentCopy.edi.dateCompleted,
            },
          },
        });
      }

      params.push({
        updateEdiEnrollmentApprovalNumber: {
          ediEnrollmentApprovalNumber: {
            type: "String!",
            value: this.enrollmentCopy.edi.approvalNumber || "",
          },
        },
      });

      params.push({
        updateEdiEnrollmentNote: {
          ediEnrollmentNote: {
            type: "String!",
            value: this.enrollmentCopy.edi.note || "",
          },
        },
      });

      if (this.enrollmentCopy.eft.dateSubmitted) {
        params.push({
          updateEftEnrollmentDateSubmitted: {
            eftEnrollmentDateSubmitted: {
              type: "String!",
              value: this.enrollmentCopy.eft.dateSubmitted,
            },
          },
        });
      }

      if (this.enrollmentCopy.eft.dateCompleted) {
        params.push({
          updateEftEnrollmentDateCompleted: {
            eftEnrollmentDateCompleted: {
              type: "String!",
              value: this.enrollmentCopy.eft.dateCompleted,
            },
          },
        });
      }

      params.push({
        updateEftEnrollmentApprovalNumber: {
          eftEnrollmentApprovalNumber: {
            type: "String!",
            value: this.enrollmentCopy.eft.approvalNumber || "",
          },
        },
      });

      params.push({
        updateEftEnrollmentNote: {
          eftEnrollmentNote: {
            type: "String!",
            value: this.enrollmentCopy.eft.note || ""
          },
        },
      });

      if (this.enrollmentCopy.era.dateSubmitted) {
        params.push({
          updateEraEnrollmentDateSubmitted: {
            eraEnrollmentDateSubmitted: {
              type: "String!",
              value: this.enrollmentCopy.era.dateSubmitted,
            },
          },
        });
      }

      if (this.enrollmentCopy.era.dateCompleted) {
        params.push({
          updateEraEnrollmentDateCompleted: {
            eraEnrollmentDateCompleted: {
              type: "String!",
              value: this.enrollmentCopy.era.dateCompleted,
            },
          },
        });
      }

      params.push({
        updateEraEnrollmentApprovalNumber: {
          eraEnrollmentApprovalNumber: {
            type: "String!",
            value: this.enrollmentCopy.era.approvalNumber || "",
          },
        },
      });

      params.push({
        updateEraEnrollmentNote: {
          eraEnrollmentNote: {
            type: "String!",
            value: this.enrollmentCopy.era.note || "",
          },
        },
      });
      return params;
    },
  },

  async beforeMount() {
    this.statusOptions = await this.actions.getEnrollmentStatuses();
    this.getSelectedPayor();
    this.$emit("afterLoaded")
  },
};
