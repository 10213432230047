import VuetifyPdf from "vuetify-pdfjs/src/App.vue";
import axios from "axios";

export default {
  name: "Demographic",
  components: {
    VuetifyPdf,
  },
  props: {
    sectionDisabled: {
      type: Boolean,
      require: true,
    },
    sameAddress: {
      type: Boolean,
      default: false,
    },
    payor: {
      type: Object,
      require: true,
    },
    file: {
      type: File,
    },
    attachment: {
      type: Object,
      default: null,
    },
  },
  computed: {
    __Payor: {
      get() {
        return this.payor;
      },
      set(value) {
        this.$emit("update:payor", value);
      },
    },
    __SameAddress: {
      get() {
        return this.sameAddress;
      },
      set(value) {
        this.renderComponent = false;
        this.$nextTick(() => this.renderComponent = true);
        if (!value) {
          this.payor.payorAppealsAddress.id = null;
          this.payor.payorAppealsAddress.uuid = null;
          setTimeout(() => {
            this.$refs.payorAppealsAddress.$refs.form.validateAsync();
          }, 800);
        }
        this.$emit("update:sameAddress", value);
      },
    },
    __AppealsAddress: {
      get() {
        if (this.sameAddress) {
          return this.__Payor.payorClaimsAddress;
        }
        return this.__Payor.payorAppealsAddress;
      },
      set(value) {
        const objectKey = this.sameAddress ? 'payorClaimsAddress' : 'payorAppealsAddress';
        this.$emit("update:payor", {
          ...this.payor,
          [objectKey]: {
            ...this.payor[objectKey],
            ...value,
          }
        });
      },
    },
    __File: {
      get() {
        return this.file;
      },
      set(value) {
        this.$emit("update:file", value);
      },
    },
  },
  data() {
    return {
      attachmentFaxCover: null,
      hasAttachment: false,
      hasAttachmentCopy: false,
      hasOtherAddress: false,
      faxCoverFileSrc: "",
      renderComponent: true,
      rules: {
        file: {
          fileSize: (file) =>
            this.validateFileSize(file, 1000) || "File must not more than 1 gb",
          required: (val) => {
            return (
              (typeof val === "object" && val !== null) ||
              "This field is required"
            );
          },
          specialCharac:(val)=>{
            if(val)
              return !/[#&+]/g.test(val.name) || "File name must not have \"#&+\" characters.";
            return true
          }
        },
        required: val => !["", null, undefined].includes(val) || "This field is required.",
        onlyNumbers: (val) => /^\d+$/.test(val) || "This field only accepts numbers",
      },
    };
  },
  methods: {
    checkIfFilesCleared(){
      if(this.hasAttachmentCopy != this.hasAttachment){
        return true;
      }
      return false;
    },
    clearFile() {
      this.hasAttachmentCopy = false;
    },
    blurAddressFields() {
      if(this.__Payor.payorOtherAddress.id) return;
      setTimeout(() => {
        this.$refs.payorOtherInformation.$refs.form.resetValidation();
      }, 150);
    },
    preview() {
      window.open(this.faxCoverFileSrc);
    },
    async renderCoverFile() {
      if (this.attachment.id) {
        let file = {
          srcFilename: this.attachment.filename,
          destFilename: this.attachment.filename,
        };
        const result = await axios.get(
          `/payor/get-file?srcFilename=${file.srcFilename}&destFilename=${file.destFilename}&mimeType=application/pdf`,
          { responseType: "blob" }
        );
        if (result.status == 200) {
          const blob = new Blob([result.data], { type: "application/pdf" });
          this.faxCoverFileSrc = window.URL.createObjectURL(blob);
        }
      }
    },
    copyAddress() {
      if (this.__IsSameAddress) {
        this.__Payor.payorAppealsAddress = {
          ...this.__Payor.payorClaimsAddress,
        };
      }
    },
    async validateForm() {
      this.copyAddress();
      let validateForms = [
        await this.$refs.payorAppealsAddress.$refs.form.validateAsync(),
        await this.$refs.payorClaimsAddress.$refs.form.validateAsync(),
        await this.$refs.payorClaimPhone.$refs.form.validateAsync(),
        await this.$refs.payorAppealsPhone.$refs.form.validateAsync(),
        await this.$refs.payorClaimFax.$refs.form.validateAsync(),
        await this.$refs.payorAppealsFax.$refs.form.validateAsync(),
        await this.$refs.form.validateAsync(),

        this.__Payor.payorClaimFax.id || this.__Payor.payorClaimFax.phone != ""
          ? await this.$refs.payorClaimFax.$refs.form.validateAsync()
          : true,
        this.__Payor.payorAppealsFax.id ||
        this.__Payor.payorAppealsFax.phone != ""
          ? await this.$refs.payorAppealsFax.$refs.form.validateAsync()
          : true,

        this.__Payor.payorOtherAddress.id ||
        this.__Payor.payorOtherAddress.line1 != "" ||
        this.__Payor.payorOtherAddress.line2 != "" ||
        this.__Payor.payorOtherAddress.city != "" ||
        this.__Payor.payorOtherAddress.postalCode != "" ||
        (this.__Payor.payorOtherAddress.country != "" &&
          this.__Payor.payorOtherAddress.country != null) ||
        this.hasOtherAddress
          ? await this.$refs.payorOtherInformation.$refs.form.validateAsync()
          : true,
      ];
      return validateForms.filter((bool) => !bool).length === 0;
    },
  },
  async beforeMount() {
    this.attachmentFaxCover = JSON.parse(JSON.stringify(this.attachment));
    if (this.attachment.id){
      this.hasAttachment = true;
      this.hasAttachmentCopy= true;
    }
    if (this.__Payor.payorOtherAddress.id) this.hasOtherAddress = true;
  },
  async mounted() {
    setTimeout(async () => {
      await this.renderCoverFile();
    }, 500);
  },
};
