import actions from "modules/actions/payor-actions";
import AdditionalInformation from "./sections/AdditionalInformation";
import ClaimSubmission from "./sections/ClaimSubmission";
import Demographics from "./sections/Demographics";
import Enrollment from "./sections/Enrollment";
import PriorAuthorization from "./sections/PriorAuthorization";
import GeneralInformation from "./sections/GeneralInformation";
import { uuid } from "vue-uuid";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

export default {
  name: "AddPayor",
  components: {
    AdditionalInformation,
    ClaimSubmission,
    Demographics,
    Enrollment,
    PriorAuthorization,
    GeneralInformation,
  },
  props: { payor: Object, type: String },
  data() {
    return {
      panels: [0, 1, 2, 3, 4, 5],
      preventToClose: false,
      hasChanges: false,
      isSameAddress: false,
      priorFile: null,
      demogFile: null,
      enrollFiles: null,
      payorCopy: {
        id: null,
        insuranceCompanyId: "",
        claimsTimelyFilingLimit: 0,
        resubmissionTimelyFilingLimit: 0,
        appealsTimelyFilingLimit: 0,
        payorAccountType: "",
        payorSubGroup: "",
        payorGroup: "",
        name: "",
        payorEdi: "",
        keywordSearchTags: "",
        payorInterlinkTag: "",
        effectivityDate: "",
        termDate: "",
        payorClaimsAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorClaimPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorClaimFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorAppealsAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorAppealsPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorAppealsFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorClaimFaxNote: "",
        payorAppealFaxNote: "",
        payorOtherAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorOtherAddressDescription: "",
        ediEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        eftEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        eraEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        authorizationFileAttachments: {
          id: null,
          filename: "",
          created: "",
        },
        authorizationRequirement: false,
        authorizationRequirementDetails: "",
        authorizationRequirementNote: "",
        ediEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        eftEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        eraEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        ediEnrollmentRequirement: false,
        ediEnrollmentDateSubmitted: "",
        ediEnrollmentDateCompleted: "",
        ediEnrollmentApprovalNumber: "",
        ediEnrollmentNote: "",
        eftEnrollmentRequirement: false,
        eftEnrollmentDateSubmitted: "",
        eftEnrollmentDateCompleted: "",
        eftEnrollmentApprovalNumber: "",
        eftEnrollmentNote: "",
        eraEnrollmentRequirement: false,
        eraEnrollmentDateSubmitted: "",
        eraEnrollmentDateCompleted: "",
        eraEnrollmentApprovalNumber: "",
        eraEnrollmentNote: "",
        primaryInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        secondaryInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        supplementalInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        claimFilingIndicator: {
          id: "",
          claimFilingIndicator: "",
        },
        claimSubmissionNotes: "",
        achBankInfo: "",
      },
      enrollmentCopy: {},
      isLoading: false,
      actions: new actions(),
      createdAppealFax: null,
      createdAppealPhone: null,
      createdClaimFax: null,
      createdClaimPhone: null,
      createdAppealAddress: null,
      createdClaimAddress: null,
      createdOtherAddress: null,
      createdPhotoAppeals: null,
      createdPhotoAuthorization: null,
      createdPhotoEdi: null,
      createdPhotoEra: null,
      createdPhotoEft: null,
      progress: 0,
      progressLabel: "",
      errorCodeToBeAdd: [],

      isChildrenLoaded: [],
      totalChildrenLoading: 3, 
      loaderType:
      "article, card-heading, image, card-heading, list-item-one-line, image, actions",
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    phoneCountryCodeOptions() {
      return this.getAllPhoneCountryCodes().map((country) => {
        return {
          shortName: country.id,
          label: `${country.name} (+${country.phone})`,
          value: `${country.name}-${country.phone}`,
          code: country.phone,
        };
      });
    },
    loadingChildCompleted(){
      return [...this.isChildrenLoaded].filter((item)=>item).length === this.totalChildrenLoading
    }
  },
  watch: {
    priorFile(newVal, oldVal) {
      if (newVal !== oldVal) this.preventToClose = true;
    },
    demogFile(newVal, oldVal) {
      if (newVal !== oldVal) this.preventToClose = true;
    },
  },
  methods: {
    childrenAfterLoaded(){
      this.isChildrenLoaded.push(true);
    },
    comparePayorObj() {
      const obj = Object.keys(this.payorCopy);
      obj.forEach((index) => {
        if (
          index == "appealsFaxCover" ||
          index == "payorClaimFaxNote" ||
          index == "payorAppealFaxNote"
        ) {
          if (!isEqual(this.payor[index], this.payorCopy[index])) {
            this.hasChanges = true;
            return;
          }
        } else if (index.includes("Phone") || index.includes("Fax")) {
          const resultOfPhone = isEqualWith(
            this.payor[index],
            this.payorCopy[index],
            (value, otherValue) => {
              if (
                value.countryCode == undefined ||
                otherValue.countryCode == undefined
              )
                return true;
              return (
                value.phone == otherValue.phone &&
                value.countryCode.code == otherValue.countryCode.code
              );
            }
          );
          if (!resultOfPhone) {
            this.hasChanges = true;
            return;
          }
        } else if (!isEqual(this.payor[index], this.payorCopy[index])) {
          this.hasChanges = true;
          return;
        }
      });

      if (!this.hasChanges) {
        const isEmpty = Object.values(
          this.$refs.enrollment.getUploadParam()
        ).every((x) => x === null || x === "");
        const enrollFilesCopy = { ...this.$refs.enrollment.getUploadParam() };
        if (!isEmpty) {
          if (!isEqual(this.enrollFiles, enrollFilesCopy)) {
            this.hasChanges = true;
          }
        }
      }

      if (this.type == "edit") {
        let filesCleared = [
          this.$refs.demographics.checkIfFilesCleared(),
          this.$refs.priorAuthorization.checkIfFilesCleared(),
          this.$refs.enrollment.checkIfFilesCleared(),
        ];
        const hasClearedfiles = filesCleared.filter((bool) => bool).length >= 1;
        if (hasClearedfiles) {
          this.hasChanges = true;
        }
      }
    },
    onCancel() {
      this.comparePayorObj();
      if (this.hasChanges && this.type !== "view") {
        this.preventToClose = true;
        this.$emit("closeModal", true);
      } else {
        this.$emit("closeModal");
      }
    },
    stopPropagation(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    async validateSectionForms() {
      let validateForms = [
        await this.$refs.generalInformation.$refs.form.validateAsync(),
        await this.$refs.demographics.validateForm(),
        await this.$refs.priorAuthorization.$refs.form.validateAsync(),
        await this.$refs.claimSubmission.$refs.form.validateAsync(),
        await this.$refs.enrollment.$refs.ediForm.validateForm(),
        await this.$refs.enrollment.$refs.eraForm.validateForm(),
        await this.$refs.enrollment.$refs.eftForm.validateForm(),
      ];
      return validateForms.filter((bool) => !bool).length === 0;
    },
    async resetValidation() {
      await this.$refs.payorForm.resetValidation(),
        await this.$refs.payorAppealsAddress.$refs.form.resetValidation(),
        await this.$refs.payorClaimsAddress.$refs.form.resetValidation(),
        await this.$refs.payorClaimPhone.$refs.form.resetValidation(),
        await this.$refs.payorAppealsPhone.$refs.form.resetValidation(),
        await this.$refs.payorClaimFax.$refs.form.resetValidation();
      await this.$refs.payorAppealsFax.$refs.form.resetValidation();
    },
    async createPhone(phone) {
      let param = {
        phone: {
          type: "String!",
          value: this.sanitizePhoneString(phone.phone),
          unincludeToFields: true,
        },

        areaCode: {
          type: "String!",
          value: this.sanitizePhoneString(phone.areaCode),
          unincludeToFields: true,
        },
        collectionQueries: [],
        queries: {},
      };
      return await this.actions.createPhone(param);
    },
    async createAddress(address) {
      let params = {
        line1: {
          type: "String!",
          value: address.line1,
        },
        line2: {
          type: "String",
          value: address.line2,
        },
        city: {
          type: "String",
          value: address.city,
        },
        postalCode: {
          type: "String",
          value: address.postalCode,
        },
        country: {
          type: "String",
          value: address.country,
        },
        stateProvince: {
          type: "String",
          value: address.stateProvince,
        },
      };
      return await this.actions.createAddress({ params });
    },
    async createAttachment(filename) {
      return await this.actions.createPayorAttachment({
        updateFilename: {
          filename: {
            type: "String",
            value: filename,
          },
        },
      });
    },
    async processPhones() {
      if (
        this.payorCopy.payorAppealsFax &&
        !this.payorCopy.payorAppealsFax.id &&
        this.payorCopy.payorAppealsFax.phone
      ) {
        const result = await this.createPhone(this.payorCopy.payorAppealsFax);
        this.createdAppealFax = result.id;
      }

      if (
        this.payorCopy.payorAppealsPhone &&
        !this.payorCopy.payorAppealsPhone.id
      ) {
        const result = await this.createPhone(this.payorCopy.payorAppealsPhone);
        this.createdAppealPhone = result.id;
      }

      if (
        this.payorCopy.payorClaimPhone &&
        !this.payorCopy.payorClaimPhone.id
      ) {
        const result = await this.createPhone(this.payorCopy.payorClaimPhone);
        this.createdClaimPhone = result.id;
      }
      if (
        this.payorCopy.payorClaimFax &&
        !this.payorCopy.payorClaimFax.id &&
        this.payorCopy.payorClaimFax.phone
      ) {
        const result = await this.createPhone(this.payorCopy.payorClaimFax);
        this.createdClaimFax = result.id;
      }
    },
    async processAddresses() {
      if (
        this.payorCopy.payorClaimsAddress &&
        !this.payorCopy.payorClaimsAddress.uuid
      ) {
        const result = await this.createAddress(
          this.payorCopy.payorClaimsAddress
        );
        this.createdClaimAddress = result.id;
      }
      if (
        !this.isSameAddress &&
        this.payorCopy.payorAppealsAddress &&
        !this.payorCopy.payorAppealsAddress.uuid
      ) {
        const result = await this.createAddress(
          this.payorCopy.payorAppealsAddress
        );
        this.createdAppealAddress = result.id;
      } else {
        this.createdAppealAddress = this.createdClaimAddress;
      }
      if (
        this.payorCopy.payorOtherAddress.line1.length > 1 &&
        this.payorCopy.payorOtherAddress &&
        !this.payorCopy.payorOtherAddress.uuid
      ) {
        const result = await this.createAddress(
          this.payorCopy.payorOtherAddress
        );
        this.createdOtherAddress = result.id;
      }
    },

    async uploadPhotos() {
      if (this.priorFile) {
        this.progressLabel = "Uploading Prior Authorization Attachment...";
        const result = await this.actions.uploadFile(
          this.priorFile,
          uuid.v1(),
          "prior_auth"
        );
        this.createdPhotoAuthorization = await this.createAttachment(
          result.response.data.name
        );
      }
      if (this.demogFile) {
        this.progressLabel = "Uploading Demographics Attachment...";
        const result = await this.actions.uploadFile(
          this.demogFile,
          uuid.v1(),
          "fax_cover"
        );
        this.createdPhotoAppeals = await this.createAttachment(
          result.response.data.name
        );
      }
      this.enrollFiles = { ...this.$refs.enrollment.getUploadParam() };

      this.progress = 75;
      if (this.enrollFiles.ediFile) {
        this.progressLabel = "Uploading EDI Attachment...";
        const result = await this.actions.uploadFile(
          this.enrollFiles.ediFile,
          uuid.v1(),
          "edi"
        );
        this.createdPhotoEdi = await this.createAttachment(
          result.response.data.name
        );
      }
      this.progress = 77;
      if (this.enrollFiles.eftFile) {
        this.progressLabel = "Uploading EFT Attachment...";
        const result = await this.actions.uploadFile(
          this.enrollFiles.eftFile,
          uuid.v1(),
          "eft"
        );
        this.createdPhotoEft = await this.createAttachment(
          result.response.data.name
        );
      }

      this.progress = 79;
      if (this.enrollFiles.eraFile) {
        this.progressLabel = "Uploading ERA Attachment...";
        const result = await this.actions.uploadFile(
          this.enrollFiles.eraFile,
          uuid.v1(),
          "era"
        );
        this.createdPhotoEra = await this.createAttachment(
          result.response.data.name
        );
      }
    },
    async savePayor() {
      try {
        this.progress = 0;
        this.isLoading = true;
        this.progressLabel = "Validating Fields...";
        const allSectionsAreValidated = await this.validateSectionForms();

        if (!allSectionsAreValidated) {
          this.isLoading = false;
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }
        this.progress = 25;
        this.progressLabel = "Processing Contact Numbers...";
        await this.processPhones();
        this.progress = 50;
        this.progressLabel = "Processing Addresses...";
        await this.processAddresses();
        this.progress = 70;
        this.progressLabel = "Uploading Assets...";
        await this.uploadPhotos();
        this.progress = 80;
        this.progressLabel = "Saving Payor...";
        let params = {};
        if (this.payorCopy.id) {
          params = {
            insuranceCompanyId: {
              type: "UUID!",
              value: this.payorCopy.id,
              unincludeToFields: true,
            },
            collectionQueries: [
              {
                updatePayorEdi: {
                  data: {
                    type: "String!",
                    value: this.payorCopy.payorEdi,
                  },
                },
              },
              {
                updateKeywordSearchTags: {
                  keywordSearchTags: {
                    type: "String!",
                    value: this.payorCopy.keywordSearchTags || "",
                  },
                },
              },
              {
                updateClaimsTimelyFilingLimit: {
                  data: {
                    type: "Int!",
                    value: parseInt(
                      this.payorCopy.resubmissionTimelyFilingLimit
                    ),
                    unincludeToFields: true,
                  },
                },
              },
              {
                updateResubmissionTimleyFilingLimit: {
                  data: {
                    type: "Int!",
                    value: parseInt(this.payorCopy.claimsTimelyFilingLimit),
                    unincludeToFields: true,
                  },
                },
              },
              {
                updateAppealsTimelyFilingLimit: {
                  data: {
                    type: "Int!",
                    value: parseInt(this.payorCopy.appealsTimelyFilingLimit),
                    unincludeToFields: true,
                  },
                },
              },
              {
                updatePayorGroup: {
                  payorGroup: {
                    type: "PayorGroup!",
                    value: this.payorCopy.payorGroup,
                    unincludeToFields: true,
                  },
                },
              },
              {
                updatePayorSubGroup: {
                  payorSubGroup: {
                    type: "PayorSubGroup!",
                    value: this.payorCopy.payorSubGroup,
                    unincludeToFields: true,
                  },
                },
              },
              {
                updateName: {
                  name: {
                    type: "String!",
                    value: this.payorCopy.name,
                    unincludeToFields: true,
                  },
                },
              },
              {
                updateInsuranceCompanyId: {
                  insuranceCompanyId: {
                    type: "String!",
                    value: this.payorCopy.insuranceCompanyId,
                    unincludeToFields: true,
                  },
                },
              },

              {
                updateAuthorizationRequirement: {
                  authorizationRequirement: {
                    type: "Boolean!",
                    value: this.payorCopy.authorizationRequirement,
                  },
                },
              },
            ],
            queries: {},
          };
        } else {
          params = {
            name: {
              type: "String!",
              value: this.payorCopy.name,
              unincludeToFields: true,
            },
            insuranceSourceType: {
              type: "InsuranceSourceType!",
              value: "Private",
              unincludeToFields: true,
            },
            insuranceCompanyId: {
              type: "String!",
              value: this.payorCopy.insuranceCompanyId,
              unincludeToFields: true,
            },
            payorGroup: {
              type: "PayorGroup!",
              value: this.payorCopy.payorGroup,
              unincludeToFields: true,
            },
            payorSubGroup: {
              type: "PayorSubGroup!",
              value: this.payorCopy.payorSubGroup,
              unincludeToFields: true,
            },
            collectionQueries: [
              {
                updatePayorEdi: {
                  data: {
                    type: "String!",
                    value: this.payorCopy.payorEdi,
                  },
                },
              },
              {
                updateClaimsTimelyFilingLimit: {
                  data: {
                    type: "Int!",
                    value: parseInt(
                      this.payorCopy.resubmissionTimelyFilingLimit
                    ),
                    unincludeToFields: true,
                  },
                },
              },
              {
                updateResubmissionTimleyFilingLimit: {
                  data: {
                    type: "Int!",
                    value: parseInt(this.payorCopy.claimsTimelyFilingLimit),
                    unincludeToFields: true,
                  },
                },
              },
              {
                updateAppealsTimelyFilingLimit: {
                  data: {
                    type: "Int!",
                    value: parseInt(this.payorCopy.appealsTimelyFilingLimit),
                    unincludeToFields: true,
                  },
                },
              },
            ],
            queries: {},
          };
          if (this.payorCopy.keywordSearchTags) {
            params.collectionQueries.push({
              updateKeywordSearchTags: {
                keywordSearchTags: {
                  type: "String!",
                  value: this.payorCopy.keywordSearchTags,
                },
              },
            });
          }
        }
        this.processCollectionQuery(params);
        if (this.payorCopy.id) {
          await this.actions.updatePayor(params);
        } else {
          const createPayor = await this.actions.createPayor(params);
          if (createPayor.id) {
            let collectionQueries = [
              {
                setPayorId: {
                  insuranceCompanyId: {
                    type: "UUID!",
                    value: createPayor.id,
                  },
                },
              },
            ];
            for (let i = 0; i < this.errorCodeToBeAdd.length; i++) {
              collectionQueries.push({
                addBillingErrorCode: {
                  billingErrorCodeId: {
                    type: "UUID!",
                    value: this.errorCodeToBeAdd[i],
                  },
                },
              });
            }
            await this.actions.createBillingErrorAccession({
              collectionQueries,
            });
          }
        }

        this.showNotifyMessage({
          message: "Successfully saved payor account data.",
          type: "success",
        });
        this.$emit("closeModal");
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
      this.isLoading = false;
    },
    processCollectionQuery(params) {
      if (this.createdPhotoAuthorization) {
        params.collectionQueries.push({
          setAuthorizationFileAttachments: {
            payorFileAttachmentId: {
              type: "UUID!",
              value: this.createdPhotoAuthorization.id,
            },
          },
        });
      }
      if (this.createdPhotoAppeals) {
        params.collectionQueries.push({
          setAppealsFaxCover: {
            payorFileAttachmentId: {
              type: "UUID!",
              value: this.createdPhotoAppeals.id,
            },
          },
        });
      }

      if (this.createdPhotoEdi) {
        params.collectionQueries.push({
          setEdiEnrollmentFileAttachments: {
            ediEnrollmentFileAttachmentsId: {
              type: "UUID!",
              value: this.createdPhotoEdi.id,
            },
          },
        });
      }
      if (this.createdPhotoEft) {
        params.collectionQueries.push({
          setEftEnrollmentFileAttachments: {
            eftEnrollmentFileAttachmentsId: {
              type: "UUID!",
              value: this.createdPhotoEft.id,
            },
          },
        });
      }
      if (this.createdPhotoEra) {
        params.collectionQueries.push({
          setEraEnrollmentFileAttachments: {
            eraEnrollmentFileAttachmentsId: {
              type: "UUID!",
              value: this.createdPhotoEra.id,
            },
          },
        });
      }

      params.collectionQueries.push({
        updateValidityDates: {
          effectivityDate: {
            type: "String!",
            value: this.payorCopy.effectivityDate
              ? this.$options.filters.getTimeDate(
                  this.payorCopy.effectivityDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  this.getTimezoneOffset({
                    date: this.payorCopy.effectivityDate,
                  })
                ).utc
              : "",
            unincludeToFields: true,
          },
          termDate: {
            type: "String!",
            value: this.payorCopy.termDate
              ? this.$options.filters.getTimeDate(
                  this.payorCopy.termDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  {
                    days: 1,
                    ...this.getTimezoneOffset({
                      date: this.payorCopy.termDate,
                      minusSeconds: 1,
                    }),
                  }
                ).utc
              : "",
            unincludeToFields: true,
          },
          mutationReturn: ["success", "errors"],
        },
      });

      if (this.createdClaimFax) {
        params.collectionQueries.push({
          setPayorClaimFax: {
            phoneId: {
              type: "UUID!",
              value: this.createdClaimFax,
            },
          },
        });
      } else if (this.payorCopy.payorClaimFax.id) {
        params.collectionQueries.push({
          updatePhone: {
            phoneId: {
              type: "UUID!",
              value: this.payorCopy.payorClaimFax.id,
              unincludeToFields: true,
            },
            phone: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorClaimFax.phone
              ),
              unincludeToFields: true,
            },
            areaCode: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorClaimFax.areaCode
              ),
              unincludeToFields: true,
            },
          },
        });
      }

      if (this.createdClaimPhone) {
        params.collectionQueries.push({
          setPayorClaimPhone: {
            phoneId: {
              type: "UUID!",
              value: this.createdClaimPhone,
            },
          },
        });
      } else {
        params.collectionQueries.push({
          updatePhone: {
            phoneId: {
              type: "UUID!",
              value: this.payorCopy.payorClaimPhone.id,
              unincludeToFields: true,
            },
            phone: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorClaimPhone.phone
              ),
              unincludeToFields: true,
            },
            areaCode: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorClaimPhone.areaCode
              ),
              unincludeToFields: true,
            },
          },
        });
      }

      if (this.createdAppealFax) {
        params.collectionQueries.push({
          setPayorAppealsFaxCover: {
            phoneId: {
              type: "UUID!",
              value: this.createdAppealFax,
            },
          },
        });
      } else if (this.payorCopy.payorAppealsFax.id) {
        params.collectionQueries.push({
          updatePhone: {
            phoneId: {
              type: "UUID!",
              value: this.payorCopy.payorAppealsFax.id,
              unincludeToFields: true,
            },
            phone: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorAppealsFax.phone
              ),
              unincludeToFields: true,
            },
            areaCode: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorAppealsFax.areaCode
              ),
              unincludeToFields: true,
            },
          },
        });
      }

      if (this.createdAppealPhone) {
        params.collectionQueries.push({
          setPayorAppealsPhone: {
            phoneId: {
              type: "UUID!",
              value: this.createdAppealPhone,
            },
          },
        });
      } else {
        params.collectionQueries.push({
          updatePhone: {
            phoneId: {
              type: "UUID!",
              value: this.payorCopy.payorAppealsPhone.id,
              unincludeToFields: true,
            },
            phone: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorAppealsPhone.phone
              ),
              unincludeToFields: true,
            },
            areaCode: {
              type: "String!",
              value: this.sanitizePhoneString(
                this.payorCopy.payorAppealsPhone.areaCode
              ),
              unincludeToFields: true,
            },
          },
        });
      }

      let addressAppealsParam = null;
      if (this.isSameAddress && this.createdAppealAddress) {
        addressAppealsParam = this.createdAppealAddress;
      } else if (this.isSameAddress && !this.createdAppealAddress) {
        addressAppealsParam = this.payorCopy.payorClaimsAddress.id;
      } else addressAppealsParam = this.createdAppealAddress;

      if (this.createdAppealAddress || this.isSameAddress) {
        params.collectionQueries.push({
          setPayorAppealsAddress: {
            addressId: {
              type: "UUID!",
              value: addressAppealsParam,
            },
          },
        });
      } else {
        params.collectionQueries.push({
          updateAddress: {
            addressId: {
              type: "UUID!",
              value: this.payorCopy.payorAppealsAddress.id,
            },
            line1: {
              type: "String",
              value: this.payorCopy.payorAppealsAddress.line1,
            },
            line2: {
              type: "String",
              value: this.payorCopy.payorAppealsAddress.line2,
            },
            city: {
              type: "String",
              value: this.payorCopy.payorAppealsAddress.city,
            },
            postalCode: {
              type: "String",
              value: this.payorCopy.payorAppealsAddress.postalCode,
            },
            country: {
              type: "String",
              value: this.payorCopy.payorAppealsAddress.country,
            },
            stateProvince: {
              type: "String",
              value: this.payorCopy.payorAppealsAddress.stateProvince,
            },
          },
        });
      }

      if (this.createdClaimAddress) {
        params.collectionQueries.push({
          setPayorClaimsAddress: {
            addressId: {
              type: "UUID!",
              value: this.createdClaimAddress,
            },
          },
        });
      } else {
        params.collectionQueries.push({
          updateAddress: {
            addressId: {
              type: "UUID!",
              value: this.payorCopy.payorClaimsAddress.id,
            },
            line1: {
              type: "String",
              value: this.payorCopy.payorClaimsAddress.line1,
            },
            line2: {
              type: "String",
              value: this.payorCopy.payorClaimsAddress.line2,
            },
            city: {
              type: "String",
              value: this.payorCopy.payorClaimsAddress.city,
            },
            postalCode: {
              type: "String",
              value: this.payorCopy.payorClaimsAddress.postalCode,
            },
            country: {
              type: "String",
              value: this.payorCopy.payorClaimsAddress.country,
            },
            stateProvince: {
              type: "String",
              value: this.payorCopy.payorClaimsAddress.stateProvince,
            },
          },
        });
      }

      params.collectionQueries.push({
        updatePayorInterlinkTag: {
          payorInterlinkTag: {
            type: "String!",
            value: this.payorCopy.payorInterlinkTag || "",
          },
        },
      });

      params.collectionQueries.push({
        updatePayorClaimFaxNote: {
          payorClaimFaxNote: {
            type: "String!",
            value: this.payorCopy.payorClaimFaxNote || "",
          },
        },
      });

      params.collectionQueries.push({
        updatePayorAppealFaxNote: {
          payorAppealFaxNote: {
            type: "String!",
            value: this.payorCopy.payorAppealFaxNote || "",
          },
        },
      });

      if (this.createdOtherAddress) {
        params.collectionQueries.push({
          setPayorOtherAddress: {
            addressId: {
              type: "UUID!",
              value: this.createdOtherAddress,
            },
          },
        });
      } else if (this.payorCopy.payorOtherAddress.line1.length > 1) {
        params.collectionQueries.push({
          updateAddress: {
            addressId: {
              type: "UUID!",
              value: this.payorCopy.payorOtherAddress.id,
            },
            line1: {
              type: "String",
              value: this.payorCopy.payorOtherAddress.line1,
            },
            city: {
              type: "String",
              value: this.payorCopy.payorOtherAddress.city,
            },
            postalCode: {
              type: "String",
              value: this.payorCopy.payorOtherAddress.postalCode,
            },
            country: {
              type: "String",
              value: this.payorCopy.payorOtherAddress.country,
            },
            stateProvince: {
              type: "String",
              value: this.payorCopy.payorOtherAddress.stateProvince,
            },
          },
        });
      }

      params.collectionQueries.push({
        updatePayorOtherAddressDescription: {
          payorOtherAddressDescription: {
            type: "String!",
            value: this.payorCopy.payorOtherAddressDescription || "",
          },
        },
      });

      params.collectionQueries.push(
        ...this.$refs.claimSubmission.getQueryCollection()
      );
      params.collectionQueries.push(
        ...this.$refs.priorAuthorization.getQueryCollection()
      );
      params.collectionQueries.push(
        ...this.$refs.enrollment.getQueryCollection()
      );

      params.collectionQueries.push(
        ...this.$refs.additionalInfo.getQueryCollection()
      );
    },
  },
  async beforeMount() {
    this.$nextTick(async () => {
      this.payorCopy = JSON.parse(JSON.stringify(this.payor));
      if (
        this.type != "add" &&
        this.payorCopy.payorClaimsAddress.id ==
          this.payorCopy.payorAppealsAddress.id
      ) {
        this.isSameAddress = true;
      }
    });
  },

  async created() {
    if (this.type == "add") {
      let errorCodes = await this.actions.getAppSettingsByKey(
        "InsuranceErrorCode"
      );

      if (errorCodes[0].value) {
        errorCodes = errorCodes[0].value
          .replaceAll("[", "")
          .replaceAll("]", "")
          .split(",");
      }
      for (let i = 0; i < errorCodes.length; i++) {
        let errorCode = await this.actions.getAllBillingError(
          1,
          null,
          {
            errorCode: errorCodes[i].trim(),
            enum_patternMatch: "EXACT",
          },
          ["errorCode"]
        );
        this.errorCodeToBeAdd.push(errorCode[0].id);
      }
    }
  },
};
