var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-searchfilter',{ref:"searchFilter",attrs:{"options":_vm.searchFilter.fields,"modelInstance":_vm.searchFilter.model,"columns":_vm.searchFilter.columns,"limitData":_vm.limitData,"limit":10,"isLoading":_vm.isDataLoading,"hasArchiveText":"Include Deactivated","defaultSort":{
          by: 'orderByCompanyId',
          order: 'ASC',
        },"hasArchive":"","hideDeleted":"","relativeUrl":"/insurance-company/all-list","queryName":"insuranceCompanies"},on:{"searchResults":_vm.searchResults,"resetDataTableOptions":_vm.resetDataTableOptions,"loading":_vm.loading}}),_c('v-custom-datatable',{ref:"dataTable",staticClass:"data-table",attrs:{"data":_vm.filteredInsuranceCompanys,"columns":_vm.columns,"row-key":"id","filter":_vm.filter,"orderBy":_vm.orderBy,"isLoading":_vm.isDataLoading,"initialLoading":_vm.initialDataLoading,"noDataLabel":"No payor/s found","flatx":"","totalCount":_vm.totalCount,"searchFilter":_vm.$refs.searchFilter,"bordered":"","withAdd":"","hasChangeStatus":"","hideDelete":"","activeLabel":"Activate","accessControl":{
          authUserHasAddAccessControl: true,
          authUserHasViewAccessControl: true,
          authUserHasEditAccessControl: true,
          authUserHasArchiveAccessControl: true,
          authUserHasDeleteAccessControl: true,
        }},on:{"onAdd":_vm.addInsuranceCompany,"onEdit":_vm.editInsuranceCompany,"onView":_vm.viewInsuranceCompany,"onChangeStatus":_vm.toggleChangeStatus}})],1)],1),(_vm.addDialog)?_c('v-dialog',{attrs:{"persistent":"","width":"1500"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('AddPayorModal',{ref:"addPayorModal",attrs:{"type":_vm.type,"dialog":_vm.dialog,"payor":_vm.payorModel},on:{"update:dialog":function($event){_vm.dialog=$event},"update:payor":function($event){_vm.payorModel=$event},"closeModal":_vm.closeModal}})],1):_vm._e(),_c('v-change-status',{attrs:{"visible":_vm.changeStatusForm.isVisible,"id":_vm.changeStatusForm.id,"isActive":_vm.changeStatusForm.isActive,"isDisabled":_vm.changeStatusForm.isDisabled,"header":"Change Status","message":"You are about to change the status of this Payor Account.","warning":"Disabling this Payor Account will update the Termination Date to date today."},on:{"close":function($event){return _vm.handleChangeStatusVisibility(false)},"submit":_vm.onSubmitChangeStatus}}),_c('v-prompt-modal',{ref:"promptModal",attrs:{"promptVisibility":_vm.dialog,"message":"Are you sure you want to Cancel?"},on:{"update:promptVisibility":function($event){_vm.dialog=$event},"update:prompt-visibility":function($event){_vm.dialog=$event},"doOk":_vm.discardChanges}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }