import actions from "modules/actions/payor-actions";

export default {
  name: "GeneralInformation",
  props: {
    sectionDisabled: {
      type: Boolean,
      require: true,
    },
    payor: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
  },
  watch: {
    __Payor: function (val) {
      this.origInsuranceId = val.insuranceCompanyId;
    },
  },
  data() {
    return {
      origInsuranceId: null,
      actions: new actions(),
      payorGroups: [],
      payorSubGroups: [],
      date: {
        effectivity: "",
        term: "",
      },
      datePicker: {
        eDateShow: false,
        tDateShow: false,
      },
      rules: {
        payorId: [
          (val) => (val && val.length !== 0) || "This field is required.",
          (val) => this.validateNameIfExist(val),
        ],
        required: [
          (val) => {
            if (
              val === null ||
              val === undefined ||
              val.length === 0 ||
              val === ""
            ) {
              return "This field is required";
            }
            return true;
          },
        ],
        date: [(val) => !val || this.isValidDate(val) || "Invalid date."],
        validTerminationDate: (val, effectivityDate) => {
          if (!val) return true;
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);

          if (effectivityDate === null || !effectivityDate.length) return true;

          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
    };
  },
  methods: {
    async getEnum(enumName) {
      try {
        if (!this[`${this.formatToCamelCase(enumName)}s`].length) {
          this[`${this.formatToCamelCase(enumName)}s`] = [
            {
              text: `Fetching ${this.formaText(enumName)}s...`,
              disabled: true,
            },
          ];

          const result = await this.actions.getEnumValues(enumName);

          if (result) {
            this[`${this.formatToCamelCase(enumName)}s`] =
              result.enumValues.map((item) => {
                return {
                  text: this.formaTitle(item.name),
                  value: item.name,
                };
              });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: `Unable to fetch data.`,
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: `Unable to fetch data.`,
        });
      }
    },
    formaTitle(value) {
      if (value === "UNK") return value;
      if (value.includes("HMO") || value.includes("PPO")) {
        const index =
          value.indexOf("HMO") >= 0
            ? value.indexOf("HMO")
            : value.indexOf("PPO");
        return (
          value.substring(0, index) + " " + value.substring(index, value.length)
        );
      }
      return value.replace(/([A-Z])/g, " $1");
    },
    formaText(value) {
      const result = value.replace(/([A-Z])/g, " $1");
      return result.toLowerCase();
    },
    formatToCamelCase(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    parseDate(date) {
      if (!date || !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return "";
      return this.$options.filters.changeDateFormat(value, format);
    },
    async validateNameIfExist(insuranceCompanyId) {
      if (this.type == "edit" && insuranceCompanyId == this.origInsuranceId)
        return true;

      try {
        let result = await this.actions.fetchPayors(
          1,
          null,
          {
            insuranceCompanyId,
            archive: true,
            enum_patternMatch: "EXACT",
          },
          ["insuranceCompanyId"]
        );
        if (result.length) {
          return "Payor Acronym already exists.";
        } else {
          return true;
        }
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while validating name.",
          type: "danger",
        });

        return "Error while validating payor name";
      }
    },
  },
  computed: {
    __Payor: {
      get() {
        return this.payor;
      },
      set(value) {
        this.$emit("update:payor", value);
      },
    },
    User() {
      return this.actions.getUser() || {};
    },
  },
  async beforeMount() {
    if (this.type != "add") {
      const enumRequests = [
        this.getEnum("PayorGroup"),
        this.getEnum("PayorSubGroup"),
      ];
      await Promise.all(
        enumRequests.map(async (item) => {
          return await item;
        })
      );
      this.$emit("afterLoaded")
    }
  },
  async mounted() {
    this.$refs.form.resetValidation();
  },
};
