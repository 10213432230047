import EnrollmentStatus from "store/models/EnrollmentStatus";

import StoreActions from "store/actions";

const storeActions = new StoreActions(EnrollmentStatus);

export default class EnrollmentStatusActions {

  async all(variables) {
    return await storeActions.query(
      {
        queryName: "enrollmentStatuses",
        relativeUrl: "/billing-enrollment-status/get-all",
        ...variables,
      },
      "To fetch all enrollment statuses"
    );
  }
}
