import ClaimSubmissionMethod from "store/models/ClaimSubmissionMethod";

import StoreActions from "store/actions";

const storeActions = new StoreActions(ClaimSubmissionMethod);

export default class ClaimSubmissionMethodActions {

  async all(variables) {
    return await storeActions.query(
      {
        queryName: "billingClaimSubmissionMethods",
        relativeUrl: "/billing-claim-submission-method/get-all",
        ...variables,
      },
      "To fetch all Claims Submission Method"
    );
  }
}
