import Actions from "modules/actions/payor-actions";
export default {
  name: "ClaimSubmission",
  props: {
    primarySubmission: {
      type: Object,
      require: true,
    },
    secondarySubmission: {
      type: Object,
      require: true,
    },
    supplementalSubmission: {
      type: Object,
      require: true,
    },
    claimSubmissionNotes: {
      type: String,
      require: true,
    },
    claimFilingIndicator: {
      type: Object,
      require: true,
    },
    sectionDisabled: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      claimFilingIndicatorOptions: [],
      claimSubmissionOptions: [],
      actions: new Actions(),
      rules: {
        required: [
          (val) => (val !== null && val !== "") || "This field is required.",
        ],
      },
    };
  },
  computed: {
    __PrimarySubmission: {
      get() {
        return this.primarySubmission;
      },
      set(value) {
        this.$emit("update:primarySubmission", value);
      },
    },
    __SecondarySubmission: {
      get() {
        return this.secondarySubmission;
      },
      set(value) {
        this.$emit("update:secondarySubmission", value);
      },
    },
    __SupplementalSubmission: {
      get() {
        return this.supplementalSubmission;
      },
      set(value) {
        this.$emit("update:supplementalSubmission", value);
      },
    },
    __ClaimFilingIndicator: {
      get() {
        return this.claimFilingIndicator;
      },
      set(value) {
        this.$emit("update:claimFilingIndicator", value);
      },
    },

    __ClaimSubmissionNotes: {
      get() {
        return this.claimSubmissionNotes;
      },
      set(value) {
        this.$emit("update:claimSubmissionNotes", value);
      },
    },
  },

  methods: {
    getQueryCollection() {
      let params = [];
      if (this.__PrimarySubmission.id) {
        params.push({
          setPrimaryInsuranceSubmissionMethod: {
            billingClaimSubmissionMethodId: {
              type: "UUID!",
              value: this.__PrimarySubmission.id,
              unincludeToFields: true,
            },
          },
        });
      }
      if (this.__SecondarySubmission.id) {
        params.push({
          setSecondaryInsuranceSubmissionMethod: {
            billingClaimSubmissionMethodId: {
              type: "UUID!",
              value: this.__SecondarySubmission.id,
              unincludeToFields: true,
            },
          },
        });
      }
      if (this.__SupplementalSubmission.id) {
        params.push({
          setSupplementalInsuranceSubmissionMethod: {
            billingClaimSubmissionMethodId: {
              type: "UUID!",
              value: this.__SupplementalSubmission.id,
              unincludeToFields: true,
            },
          },
        });
      }
      params.push({
        updateClaimSubmissionNotes: {
          claimSubmissionNotes: {
            type: "String!",
            value: this.__ClaimSubmissionNotes || "",
          },
        },
      });
      if (this.__ClaimFilingIndicator.id) {
        params.push({
          setClaimFilingIndicator: {
            claimFilingIndicatorId: {
              type: "UUID!",
              value: this.__ClaimFilingIndicator.id,
              unincludeToFields: true,
            },
          },
        });
      }

      return params;
    },
  },
  async beforeMount() {
    this.claimSubmissionOptions =
      await this.actions.getClaimSubmissionMethods();
    this.claimFilingIndicatorOptions =
      await this.actions.getClaimFilingIndicators();

    this.$emit("afterLoaded")
  },
};
