import PayorFileAttachment from "store/models/PayorFileAttachment";

import StoreActions from "store/actions";

const storeActions = new StoreActions(PayorFileAttachment);
export default class PayorFileAttachmentActions {
  async create(queries) {
    return await storeActions.mutation(
      {
        queryName: "createPayorFileAttachment",
        relativeUrl: "/payor-attachment/create-attachment",
        queries,
        shouldHaveParameters: true,
      },
      "To create payor attachment..",
      {}
    );
  }
}
