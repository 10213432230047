import BillingErrorCode from "store/models/BillingErrorCode";

import StoreActions from "store/actions";

const storeActions = new StoreActions(BillingErrorCode);

export default class BillingErrorCodeActions {
  async create(variables) {
    return await storeActions.mutation(
      {
        queryName: "createBillingErrorCode",
        relativeUrl: "/billing-err-code/create-billing-err-code",
      },
      "To create billing error code.",
      {
        ...variables,
      }
    );
  }
  async createBillingErrorAccession(variables) {
    return await storeActions.mutation(
      {
        queryName: "createBillingErrorAccession",
        relativeUrl: "/billing-err-code/create-billing-err-accession",
        queries: {},
        collectionQueries: variables.collectionQueries,
        shouldHaveParameters: true,
      },
      "To create order.",
      {}
    );
  }
  async all(variables) {
    return await storeActions.query(
      {
        queryName: "billingErrorCodes",
        relativeUrl: "/billing-err-code/get-all",
        ...variables,
      },
      "To fetch all error codes"
    );
  }
}
