import ClaimFilingIndicator from "store/models/ClaimFilingIndicator";

import StoreActions from "store/actions";

const storeActions = new StoreActions(ClaimFilingIndicator);

export default class ClaimFilingIndicatorActions {

  async all(variables) {
    return await storeActions.query(
      {
        queryName: "billingClaimFilingIndicators",
        relativeUrl: "/billing-claim-filling-indicator/get-all",
        ...variables,
      },
      "To fetch all indicator"
    );
  }
}
