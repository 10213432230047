import InsuranceCompany from "store/models/InsuranceCompany";
import Actions from "modules/actions/payor-actions";
import AddPayorModal from "./modals/AddPayorModal";

const US_COUNTRY_NAME = "United States";

export default {
  name: "InsuranceCompanies",
  components: { AddPayorModal },
  data() {
    return {
      addDialog: false,
      dialog: false,
      type: "add",
      selectedPayorId: null,
      actions: new Actions(),
      initialDataLoading: false,
      isDataLoading: false,
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      payorAccounts: [],
      filter: "",
      payorModel: {
        id: null,
        claimsTimelyFilingLimit: 0,
        resubmissionTimelyFilingLimit: 0,
        appealsTimelyFilingLimit: 0,
        payorAccountType: "",
        payorSubGroup: "",
        payorGroup: "",
        name: "",
        payorEdi: "",
        keywordSearchTag: "",
        payorInterlinkTag: "",
        effectivityDate: "",
        termData: "",
        payorClaimsAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorClaimPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorClaimFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorAppealsAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorAppealsPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorAppealsFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorClaimFaxNote: "",
        payorAppealFaxNote: "",
        payorOtherAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        appealsFaxCover: {
          filename: "",
          id: "",
        },
        payorOtherAddressDescription: "",
        eraEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        ediEnrollmentRequirement: false,
        ediEnrollmentDateSubmitted: "",
        ediEnrollmentDateCompleted: "",
        ediEnrollmentApprovalNumber: "",
        ediEnrollmentNote: "",
        eftEnrollmentRequirement: false,
        eftEnrollmentDateSubmitted: "",
        eftEnrollmentDateCompleted: "",
        eftEnrollmentApprovalNumber: "",
        eftEnrollmentNote: "",
        eraEnrollmentRequirement: false,
        eraEnrollmentDateSubmitted: "",
        eraEnrollmentDateCompleted: "",
        eraEnrollmentApprovalNumber: "",
        eraEnrollmentNote: "",
        ediEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        eftEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        eraEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        authorizationFileAttachments: {
          filename: "",
          id: "",
        },
        authorizationRequirement: false,
        authorizationRequirementDetails: "",
        authorizationRequirementNote: "",
        primaryInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        secondaryInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        supplementalInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        claimFilingIndicator: {
          id: "",
          claimFilingIndicator: "",
        },
        claimSubmissionNotes: "",
        achBankInfo: "",
      },
      orderBy: {
        name: "orderByName",
        insuranceCompanyId: "orderByCompanyId",
        payorGroup: "orderByPayorGroup",
        payorSubGroup: "orderByPayorSubGroup",
        created: "orderByDate",
        status: "orderByValidityStatus",
      },
      columns: [
        {
          name: "companyId",
          align: "left",
          text: "Payor ID",
          field: "insuranceCompanyId",
          sortable: true,
        },
        {
          name: "name",
          align: "left",
          text: "Payor Name",
          field: "name",
          sortable: true,
        },

        {
          name: "payorGroup",
          align: "left",
          text: "Payor Group",
          field: "payorGroup",
          sortable: true,
        },
        {
          name: "payorSubGroup",
          align: "left",
          text: "Payor Sub Group",
          field: "payorSubGroup",
          sortable: true,
        },
        {
          name: "created",
          align: "left",
          text: "Creation Date",
          field: "created",
          isDateColumn: true,
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          isStatusColumn: true,
          sortable: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 3,
        model: InsuranceCompany,
        fields: [
          {
            label: "Payor Name",
            name: "name",
            type: "text",
          },
          {
            label: "Payor ID",
            name: "insuranceCompanyId",
            type: "text",
          },
          {
            label: "Payor Sub Group",
            name: "payorSubGroup",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: [
              { label: "Blue", value: "Blue" },
              { label: "Blue Fed", value: "BlueFed" },
              { label: "Blue HMO", value: "BlueHMO" },
              { label: "Blue PPO", value: "BluePPO" },
              { label: "Client", value: "Client" },
              { label: "Commercial", value: "Commercial" },
              { label: "Commercial HMO", value: "CommercialHMO" },
              { label: "Commercia lPPO", value: "CommercialPPO" },
              { label: "Medicaid", value: "Medicaid" },
              {
                label: "Medicaid Manage Care",
                value: "MedicaidManageCare",
              },
              { label: "Medicare", value: "Medicare" },
              {
                label: "Medicare Manage Care",
                value: "MedicareManageCare",
              },
              { label: "Patient", value: "Patient" },
              { label: "Tricare", value: "Tricare" },
              { label: "UnknownPayor", value: "UnknownPayor" },
            ],
          },
          {
            label: "Payor Group",
            name: "payorGroup",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: [
              { label: "Client", value: "Client" },
              { label: "Commercial", value: "Commercial" },
              { label: "Patient", value: "Patient" },
              { label: "Government", value: "Government" },
              { label: "UNK", value: "UNK" },
            ],
          },
          {
            label: "Creation Date",
            name: "created",
            type: "date",
            asUtc: true,
          },
        ],
      },
      limitData: null,
      totalCount: 0,
    };
  },
  computed: {
    filteredInsuranceCompanys() {
      try {
        let data = this.payorAccounts.map((item) => {
          const payorSubGroupValue = () => {
            const translation = {
              Blue: "Blue",
              BlueFed: "Blue Fed",
              BlueHMO: "Blue HMO",
              BluePPO: "Blue PPO",
              Client: "Client",
              Commercial: "Commercial",
              CommercialHMO: "Commercial HMO",
              CommercialPPO: "Commercial PPO",
              Medicaid: "Medicaid",
              MedicaidManageCare: "Medicaid Manage Care",
              Medicare: "Medicare",
              MedicareManageCare: "Medicare Manage  Care",
              Patient: "Patient",
              Tricare: "Tricare",
              UnknownPayor: "Unknown Payor",
            };
            return item.payorSubGroup ? translation[item.payorSubGroup] : "-";
          };

          item.termDate = item.termDate
            ? this.$options.filters.getTimeDate(item.termDate, "MM/DD/YYYY")
                .zone
            : "";
          item.effectivityDate = item.effectivityDate
            ? this.$options.filters.getTimeDate(
                item.effectivityDate,
                "MM/DD/YYYY"
              ).zone
            : "";

          return {
            id: item.id,
            name: item.name,
            insuranceCompanyId: item.insuranceCompanyId,
            payorGroup: item.payorGroup,
            payorSubGroup: payorSubGroupValue(),
            isDeleted: item.isDeleted,
            created: this.$options.filters.parseDateFromString(
              item.created,
              "MM/DD/YYYY hh:mm A"
            ),
            status: !item.inactive ? "Active" : "Deactivated",
            isActive: !item.inactive,
          };
        });
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async closeModal(preventStatus) {
      if (preventStatus) {
        this.dialog = true;
      } else {
        this.dialog = false;
        this.addDialog = false;
        await this.fetch();
      }
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.payorAccounts = result;
    },
    addInsuranceCompany() {
      this.type = "add";
      this.payorModel = {
        id: null,
        claimsTimelyFilingLimit: 0,
        resubmissionTimelyFilingLimit: 0,
        appealsTimelyFilingLimit: 0,
        payorAccountType: "",
        payorSubGroup: "",
        payorGroup: "",
        name: "",
        payorEdi: "",
        keywordSearchTag: "",
        payorInterlinkTag: "",
        effectivityDate: "",
        termData: "",
        payorClaimsAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: US_COUNTRY_NAME,
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorClaimPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorClaimFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorAppealsAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: US_COUNTRY_NAME,
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        payorAppealsPhone: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorAppealsFax: {
          uuid: null,
          phone: "",
          areaCode: "",
        },
        payorClaimFaxNote: "",
        payorAppealFaxNote: "",
        payorOtherAddress: {
          uuid: null,
          line1: "",
          line2: "",
          city: "",
          postalCode: "",
          country: "",
          stateProvince: "",
          addressTypeUUID: null,
          addressType: "",
          isReadonly: false,
        },
        appealsFaxCover: {
          filename: "",
          id: "",
        },
        payorOtherAddressDescription: "",
        ediEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        eftEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        eraEnrollmentStatus: {
          id: null,
          enrollmentStatus: "",
        },
        ediEnrollmentRequirement: false,
        ediEnrollmentDateSubmitted: "",
        ediEnrollmentDateCompleted: "",
        ediEnrollmentApprovalNumber: "",
        ediEnrollmentNote: "",
        eftEnrollmentRequirement: false,
        eftEnrollmentDateSubmitted: "",
        eftEnrollmentDateCompleted: "",
        eftEnrollmentApprovalNumber: "",
        eftEnrollmentNote: "",
        eraEnrollmentRequirement: false,
        eraEnrollmentDateSubmitted: "",
        eraEnrollmentDateCompleted: "",
        eraEnrollmentApprovalNumber: "",
        eraEnrollmentNote: "",
        ediEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        eftEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        eraEnrollmentFileAttachments: {
          filename: "",
          id: "",
        },
        authorizationFileAttachments: {
          id: null,
          filename: "",
        },
        authorizationRequirement: false,
        authorizationRequirementDetails: "",
        authorizationRequirementNote: "",
        primaryInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        secondaryInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        supplementalInsuranceSubmissionMethod: {
          id: "",
          claimsSubmissionMethod: "",
        },
        claimFilingIndicator: {
          id: "",
          claimFilingIndicator: "",
        },
        claimSubmissionNotes: "",
        achBankInfo: "",
      };
      this.addDialog = true;
    },
    editInsuranceCompany(id) {
      this.type = "edit";

      this.payorModel = this.payorAccounts.find((x) => x.id == id);
      this.payorModel.payorClaimsAddress = this.payorModel
        .payorClaimsAddress || {
        uuid: null,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        country: "",
        stateProvince: "",
        addressTypeUUID: null,
        addressType: "",
        isReadonly: false,
      };
      this.payorModel.payorClaimsAddress.uuid =
        this.payorModel.payorClaimsAddress.id || null;
      this.payorModel.payorClaimPhone = this.payorModel.payorClaimPhone || {
        uuid: null,
        phone: "",
        areaCode: "",
      };
      this.payorModel.payorClaimFax = this.payorModel.payorClaimFax || {
        uuid: null,
        phone: "",
        areaCode: "",
      };
      this.payorModel.payorAppealsAddress = this.payorModel
        .payorAppealsAddress || {
        uuid: null,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        country: "",
        stateProvince: "",
        addressTypeUUID: null,
        addressType: "",
        isReadonly: false,
      };
      this.payorModel.payorAppealsAddress.uuid =
        this.payorModel.payorAppealsAddress.id || null;
      this.payorModel.payorAppealsPhone = this.payorModel.payorAppealsPhone || {
        uuid: null,
        phone: "",
        areaCode: "",
      };
      this.payorModel.payorAppealsFax = this.payorModel.payorAppealsFax || {
        uuid: null,
        phone: "",
        areaCode: "",
      };

      this.payorModel.payorOtherAddress = this.payorModel.payorOtherAddress || {
        uuid: null,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        country: "",
        stateProvince: "",
        addressTypeUUID: null,
        addressType: "",
        isReadonly: false,
      };

      this.payorModel.ediEnrollmentStatus = this.payorModel
        .ediEnrollmentStatus || {
        id: null,
        enrollmentStatus: "",
      };
      this.payorModel.eftEnrollmentStatus = this.payorModel
        .eftEnrollmentStatus || {
        id: null,
        enrollmentStatus: "",
      };
      this.payorModel.eraEnrollmentStatus = this.payorModel
        .eraEnrollmentStatus || {
        id: null,
        enrollmentStatus: "",
      };

      this.payorModel.primaryInsuranceSubmissionMethod = this.payorModel
        .primaryInsuranceSubmissionMethod || {
        id: null,
        claimsSubmissionMethod: "",
      };
      this.payorModel.secondaryInsuranceSubmissionMethod = this.payorModel
        .secondaryInsuranceSubmissionMethod || {
        id: null,
        claimsSubmissionMethod: "",
      };
      this.payorModel.supplementalInsuranceSubmissionMethod = this.payorModel
        .supplementalInsuranceSubmissionMethod || {
        id: null,
        claimsSubmissionMethod: "",
      };
      this.payorModel.claimFilingIndicator = this.payorModel
        .claimFilingIndicator || {
        id: null,
        claimFilingIndicator: "",
      };

      this.payorModel.appealsFaxCover = this.payorModel.appealsFaxCover || {
        id: null,
        filename: "",
      };

      this.payorModel.authorizationFileAttachments = this.payorModel
        .authorizationFileAttachments || {
        id: null,
        filename: "",
      };
      this.payorModel.ediEnrollmentFileAttachments = this.payorModel
        .ediEnrollmentFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.eftEnrollmentFileAttachments = this.payorModel
        .eftEnrollmentFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.eraEnrollmentFileAttachments = this.payorModel
        .eraEnrollmentFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.authorizationRequirementDetails =
        this.payorModel.authorizationRequirementDetails || "";

      this.payorModel.authorizationRequirement =
        this.payorModel.authorizationRequirement == null
          ? false
          : this.payorModel.authorizationRequirement;

      this.payorModel.authorizationRequirementNote =
        this.payorModel.authorizationRequirementNote || "";

      this.addDialog = true;
    },
    viewInsuranceCompany(id) {
      this.type = "view";
      this.payorModel = this.payorAccounts.find((x) => x.id == id);
      this.payorModel.payorClaimsAddress = this.payorModel
        .payorClaimsAddress || {
        uuid: null,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        country: "",
        stateProvince: "",
        addressTypeUUID: null,
        addressType: "",
        isReadonly: false,
      };
      this.payorModel.payorClaimPhone = this.payorModel.payorClaimPhone || {
        uuid: null,
        phone: "",
        areaCode: "",
      };
      this.payorModel.payorClaimFax = this.payorModel.payorClaimFax || {
        uuid: null,
        phone: "",
        areaCode: "",
      };
      this.payorModel.payorAppealsAddress = this.payorModel
        .payorAppealsAddress || {
        uuid: null,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        country: "",
        stateProvince: "",
        addressTypeUUID: null,
        addressType: "",
        isReadonly: false,
      };
      this.payorModel.payorAppealsPhone = this.payorModel.payorAppealsPhone || {
        uuid: null,
        phone: "",
        areaCode: "",
      };
      this.payorModel.payorAppealsFax = this.payorModel.payorAppealsFax || {
        uuid: null,
        phone: "",
        areaCode: "",
      };

      this.payorModel.payorOtherAddress = this.payorModel.payorOtherAddress || {
        uuid: null,
        line1: "",
        line2: "",
        city: "",
        postalCode: "",
        country: "",
        stateProvince: "",
        addressTypeUUID: null,
        addressType: "",
        isReadonly: false,
      };

      this.payorModel.primaryInsuranceSubmissionMethod = this.payorModel
        .primaryInsuranceSubmissionMethod || {
        id: null,
        claimsSubmissionMethod: "",
      };
      this.payorModel.secondaryInsuranceSubmissionMethod = this.payorModel
        .secondaryInsuranceSubmissionMethod || {
        id: null,
        claimsSubmissionMethod: "",
      };
      this.payorModel.supplementalInsuranceSubmissionMethod = this.payorModel
        .supplementalInsuranceSubmissionMethod || {
        id: null,
        claimsSubmissionMethod: "",
      };

      this.payorModel.claimFilingIndicator = this.payorModel
        .claimFilingIndicator || {
        id: null,
        claimFilingIndicator: "",
      };

      this.payorModel.appealsFaxCover = this.payorModel.appealsFaxCover || {
        id: null,
        filename: "",
      };

      this.payorModel.authorizationFileAttachments = this.payorModel
        .authorizationFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.ediEnrollmentFileAttachments = this.payorModel
        .ediEnrollmentFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.eftEnrollmentFileAttachments = this.payorModel
        .eftEnrollmentFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.eraEnrollmentFileAttachments = this.payorModel
        .eraEnrollmentFileAttachments || {
        id: null,
        filename: "",
      };

      this.payorModel.authorizationRequirementDetails =
        this.payorModel.authorizationRequirementDetails || "";

      this.payorModel.authorizationRequirement =
        this.payorModel.authorizationRequirement == null
          ? false
          : this.payorModel.authorizationRequirement;

      this.payorModel.authorizationRequirementNote =
        this.payorModel.authorizationRequirementNote || "";

      this.addDialog = true;
    },
    discardChanges() {
      this.dialog = false;
      this.addDialog = false;
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await this.actions.deactivatePayor(params);
      }
      return await this.actions.activatePayor(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Payor Code status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `Payor ${isActive ? "deactivated" : "activated"}.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    authUserHasAccessControl(task) {
      return this.$store.getters.authUserHasAccessControl(task);
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
