export default {
  name: "AdditionalInformation",
  props: {
    achBankInfo: {
      type: String,
      require: true,
    },
    sectionDisabled: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    __AchBankInfo: {
      get() {
        return this.achBankInfo;
      },
      set(value) {
        this.$emit("update:achBankInfo", value);
      },
    },
  },
  methods: {
    getQueryCollection() {
      let params = [];
      params.push({
        updateAchBankInfo: {
          achBankInfo: {
            type: "String!",
            value: this.__AchBankInfo || "",
          },
        },
      });
      return params;
    },
  },
};
